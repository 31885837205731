import { createApp } from 'vue'
import App from './App'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'

const ENV = process.env.NODE_ENV
if(ENV === 'development'){
    require('../mock/api');
}

const app = createApp(App)

app.use(ElementPlus)
app.mount("#app")
