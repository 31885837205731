<template>
  <div id="DSW" class="container">
    <div class="header">
      <div class="title">
        <img src="favicon.ico" class="logo" alt="XXLab">
        <a id="title">开发环境</a>
      </div>
      <div class="next-tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Jupyter Lab" name="lab"></el-tab-pane>
          <el-tab-pane label="Web IDE" name="ide"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="menu">
        <i class="el-icon-user-solid"></i>
        <span>XXLab</span>
      </div>

    </div>
    <div class="main">
      <iframe v-if="labActive" v-show="labShow" class="iframe-container" src="jupyter-lab/lab?token=XXLab"></iframe>
      <iframe v-if="ideActive" v-show="ideShow" class="iframe-container" src="ide?folder=/code"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'lab',
      labActive: true,
      labShow: true,
      ideActive: false,
      ideShow: false,
    };
  },
  methods: {
    handleClick(tab) {
      if (tab.props.name === 'lab') {
        this.labActive = true;
        this.ideShow = false;
        this.labShow = true;
      } else if (tab.props.name === 'ide') {
        this.ideActive = true;
        this.labShow = false;
        this.ideShow = true;
      }
      console.log("tabName:", tab.props.name, "labShow: ", this.labShow, " ideShow: ", this.ideShow);
    },
  }
};


</script>

<style>

.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
}

.header {
  display: flex;
  height: 42px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.title {
  margin-left: 47px;
  line-height: 42px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  position: relative;
}

.logo {
  position: absolute;
  font-size: 24px;
  left: -35px;
  top: 5px;
}

#title:hover {
  color: #fff;
}

.selector {
  position: absolute;
  line-height: 42px;
  width: 210px;
  height: 42px;
  left: 250px;
  color: #fff;
}

.menu {
  position: absolute;
  line-height: 42px;
  color: #fff;
  right: 20px;
}

.main {
  position: absolute;
  width: 100%;
  top: 42px;
  bottom: 0;
}

.next-tab {
  position: absolute;
  left: 200px;
  color: #FFFFFF;
}

.el-tabs__item{
  color:#FFFFFF !important;
}

.is-active{
  color: #409eff !important;
}

body {
  margin: 0;            /* Reset default margin */
  background-color: #000000;
}
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: calc(100vh - 60px);        /* Viewport-relative units */
  width: 100vw;
}

.el-dropdown-link {
  cursor: pointer;
  color: #FFF;
}

a:link,
a:hover,
a:active,
a:visited{
  text-decoration: none;
  color: #000;
}
</style>
