import Mock from 'mockjs'
Mock.mock('/wpai/userinfo.do',{
    code: 0,
    data: {
        "user_id": 110, //用户id
        "username": "houfachao", //用户名
        "status": 0 //状态
    }
});

Mock.mock('/dl/noauth/dev/listenv.do', {
    code: 0,
    msg: "成功",
    data: {
        devs: [
            {
                name: "dsw-01",
                url: "http://xxxx.xxxx"
            },
            {
                name: "dsw-02",
                url: "http://xxxx.xxxx"
            },
            {
                name: "dsw-03",
                url: "http://xxxx.xxxx"
            }
        ]
    }
});
